<template>
    <section :id="cmsBlock.anchorTag" v-if="cmsBlock.podcast" class="component-container white">
        <div class="detailed-podcast">
            <div class="image-col">
                <picture>
                    <template v-for="[format, set] of Object.entries(srcsets)">
                        <source v-if="format !== originalFormat" :srcset="set" sizes="(max-width: 1100px) 100vw, 50vw"
                            :type="'image/' + format.slice(1)">
                    </template>
                    <img loading="lazy" decoding="async" :srcset="srcsets[originalFormat]"
                        :type="'image/' + originalFormat.slice(1)" sizes="(max-width: 1100px) 100vw, 75vw"
                        :alt="localize(cmsBlock.podcast.data.attributes.image).data.attributes.alternativeText">
                </picture>
            </div>
            <div class="details-col">
                <div class="details">
                    <div class="date">
                        {{ podcastDate }}
                    </div>
                    <div class="title">
                        {{ localize(cmsBlock.podcast.data.attributes.title) }}
                    </div>
                    <div class="description">
                        {{ localize(cmsBlock.podcast.data.attributes.description) }}
                    </div>
                </div>
                <div class="player">
                    <PodcastPlayer :podcastCode="cmsBlock.podcast.data.attributes.iFrameCode" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import PodcastPlayer from '@/components/PageBuilder/Elements/PodcastPlayer'
import { dateFormat } from '@/helper'

export default {
    name: 'DetailedPodcastBlock',

    props: [
        'cmsBlock'
    ],

    components: {
        PodcastPlayer
    },

    computed: {
        podcastDate() {
            if (this.cmsBlock.podcast) {
                return dateFormat(this.cmsBlock.podcast.data.attributes.date)
            }
        },
        srcsets() {
            return this.getImageSrcsets(this.localize(this.cmsBlock.podcast.data.attributes.image).data.attributes)
        },

        hasWebp() {
            return this.srcsets['.webp'] ? true : false
        },

        originalFormat() {
            for (const [format, _] of Object.entries(this.srcsets)) {
                if (format !== '.webp') {
                    return format
                }
            }
            return null
        },
    }
}
</script>

<style lang="scss" scoped>
.component-container {

    .detailed-podcast {
        padding: 0;
        display: flex;
        flex-direction: row;
        max-width: $container-width;
        margin: 0 auto;
        padding: 0 getSpacing('padding-default-horizontal', 'desktop');

        @include breakpoint('tablet') {
            padding: 0 getSpacing('padding-default-horizontal', 'tablet');
        }

        @include breakpoint('mobile') {
            flex-direction: column;
            padding: 0 getSpacing('padding-default-horizontal', 'mobile');
        }

        .image-col {
            width: 375px;
            height: 375px;

            @include breakpoint('mobile') {
                width: 100%;
                height: auto;
            }

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .details-col {
            width: calc(100% - 375px);
            display: flex;
            flex-direction: column;
            background: $lightGrey;
            color: $timberGreen;
            word-break: break-word;
            hyphens: auto;
            height: 375px;
            overflow: hidden;

            @include breakpoint('mobile') {
                width: 100%;
                height: auto;
            }

            .details {
                flex-grow: 1;
                padding: getSpacing('padding-small-vertical', 'desktop') getSpacing('padding-default-horizontal', 'desktop');
                order: 0;
                height: calc(100% - 144px);
                overflow-y: scroll;
                overflow-x: hidden;

                @include breakpoint('tablet') {
                    padding: getSpacing('padding-small-vertical', 'tablet') getSpacing('padding-default-horizontal', 'tablet');
                }

                @include breakpoint('mobile') {
                    order: 1;
                    padding: getSpacing('padding-small-vertical', 'mobile') getSpacing('padding-default-horizontal', 'mobile');
                }

                .title {
                    font-size: getFontSize('defaultText', 'desktop');
                    text-transform: uppercase;
                    margin: 10px 0;

                    @include breakpoint('tablet') {
                        font-size: getFontSize('defaultText', 'tablet');
                    }

                    @include breakpoint('mobile') {
                        font-size: getFontSize('defaultText', 'mobile');
                        font-weight: bold;
                    }
                }
            }

            .player {
                order: 1;

                @include breakpoint('mobile') {
                    order: 0;
                }
            }
        }
    }
}</style>
