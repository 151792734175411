<template>
    <div class="wrapper white" ref="player">
        <div v-if="init && !appleLink" class="podcast-player podigee" v-html="podcastCode"></div>
        <div class="podcast-player" v-if="init && appleLink">
            <iframe v-if="init && appleLink" class="apple" :src="appleEmbedLink" frameborder="0" allowfullscreen></iframe>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PodcastPlayer',

    props: [
        'podcastCode',
        'appleLink',
    ],

    data() {
        return {
            init: false
        }
    },

    mounted() {
        var self = this
        var observer = new IntersectionObserver(
            function (entries) {
                if (entries[0].isIntersecting === true) {
                    self.init = true
                }
            }, {
            threshold: [0]
        }
        )
        observer.observe(this.$refs.player)
    },

    computed: {
        appleEmbedLink() {
            return this.appleLink.replace('https://podcasts.apple.com/', 'https://embed.podcasts.apple.com/');
        }
    }
}
</script>

<style lang="scss">
.wrapper {
    min-height: 2px;

    .podcast-player {
        width: 100%;

        iframe {
            height: 145px;
            width: 100%;
            background-color: transparent;
            /* margin-bottom: -7px; */

            &.apple {
                height: 180px;
            }
        }
    }

    .podigee {
        background-color: #f6f6f6;
    }
}
</style>
